<template>
    <user-dashboard-layout>
        <v-container>
            <v-row dense>
                <v-col md="4" sm="12"  ref="editorContainer">
                    <div v-if="selectedType">
                        <v-carousel v-if="!editMode" v-model="carousel" height="auto">
                            <v-carousel-item v-for="(image, i) in groupByTypes[selectedType]" :key="i">
                                <v-img id="image" contain :src="image.url"/>
                            </v-carousel-item>
                        </v-carousel>
                        <div v-else>
                            <v-btn @click="handleDownloadKonva">{{$t('social.' + social.btndld)}}</v-btn>
                            <v-stage
                                :config="configKonva"
                                ref="konvaStage"
                                @mousedown="handleStageMouseDown"
                                @touchstart="handleStageMouseDown">
                                <v-layer>
                                    <v-image ref="konvaImage" :config="imageConfig"/>
                                    <v-image ref="logo" :config="logoConfig" @transformend="handleTransformEnd"/>
                                    <v-text ref="company" :config="companyConfig" @transformend="handleTransformEnd"/>
                                    <v-transformer ref="transformer"/>
                                </v-layer>
                            </v-stage>
                        </div>
                    </div>
                </v-col>
                <v-col md="8" sm="12" class="pl-15">
                    <v-row>
                        <v-col>
                            <v-color-picker
                                width="100%"
                                v-if="editMode"
                                hide-canvas
                                hide-inputs
                                v-model="color"
                                hide-mode-switch />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-flex shrink class="ml-3">
                            <v-select outlined v-model="selectedType" :items="Object.keys(groupByTypes)" class="mr-10"/>
                        </v-flex>
                        <v-flex shrink>
                            <v-btn
                                @click="initEditor"
                                large
                                x-large
                                color="primary"
                                v-text="editMode ? 'Save' : 'Customize'"/>
                        </v-flex>
                    </v-row>
                    <hr>
                    <v-alert v-if="alert"  :type="alert.type" text v-text="alert.message" class="mt-4"/>
                    <v-row v-if="! editMode" class="mt-4">
                        <v-col v-if="groupByTypes[selectedType] && groupByTypes[selectedType][carousel]">
                            <v-btn :href="groupByTypes[selectedType][carousel].url" target="_blank">{{$t('social.' + social.btndld)}}</v-btn>
                            <v-btn @click.prevent="copyToClipboard" color="green" class="ml-3">{{$t('social.' + social.btncc)}}</v-btn>

                            <v-btn v-if="canShare" class="ml-3" @click.prevent="openShareToFacebookDialog">{{$t('social.' + social.btnshr)}}</v-btn>

                            <v-dialog v-model="showShareDialog" width="500">
                                <v-card>
                                    <v-card-title class="headline">{{$t('social.' + social.btnshr)}}</v-card-title>
                                    <v-card-text>
                                        <v-row>
                                            <v-col>
                                                <v-select
                                                    v-model="share.fb.page"
                                                    label="Page"
                                                    item-text="name"
                                                    item-value="id"
                                                    :items="fbPages"/>
                                            </v-col>
                                            <v-col>
                                                <v-datetime-picker
                                                    label="Select Datetime"
                                                    v-model="share.fb.datetime"
                                                    outlined
                                                    dense/>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="share.fb.page">
                                            <v-btn block @click="shareToFacebook">{{$t('social.' + social.btnshr)}}</v-btn>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <h1 v-text="socialGraphic.name"/>
                    <small>{{socialGraphic.created_at | formattedDate}}</small>
                    <p ref="description" v-text="socialGraphic.description"/>
                </v-col>
            </v-row>
        </v-container>
    </user-dashboard-layout>
</template>

<script>
    import UserDashboardLayout from "../../../layouts/UserDashboardLayout";
    import SocialGraphic from "../../../models/SocialGraphic";
    import KonvaHelper from "../../../helpers/KonvaHelper";
    import Konva from "konva";
    import User from "../../../models/User";
    import {mapState} from "vuex";
    import social from "@/configs/social";

    export default {
        name: "Show",
        components: {UserDashboardLayout},
        computed: {
            socialGraphicAvatar() {
                if (! this.socialGraphic.files) {
                    return ''
                }
                return this.socialGraphic.files[0].url
            },
            groupByTypes() {
                return this.$_.groupBy(this.socialGraphic.files, f => f.type)
            },
            ...mapState({
                user: state => state.auth.user
            })
        },
        data: function () {
            return {
                share: {
                    fb: {}
                },
                social,
                color: null,
                canShare: true,
                showShareDialog: false,
                fbPages: [],
                fbPageAlbums: [],
                alert: null,
                editMode: false,
                carousel: 0,
                selectedShapeName: '',
                socialGraphic: {
                    files: [{url: ''}],
                },
                selectedType: '',

                configKonva: {
                    width: 400,
                    height: 600
                },
                image: null,
                personalization: {},
                imageConfig: {
                    image: null,
                    filters: [Konva.Filters.HSL],
                },
                logoConfig: {
                    x: 10,
                    y: 50,
                    width: 40,
                    height: 40,
                    name: 'logo',
                    image: null,
                    draggable: true,
                },
                companyConfig: {
                    x: 100,
                    y: 50,
                    name: 'company',
                    fontSize: 36,
                    height: 60,
                    text: 'blabla inc.',
                    draggable: true,
                }
            }
        },
        methods: {
            copyToClipboard() {
                navigator.clipboard.writeText(this.$refs.description.innerHTML).then(() => {
                    this.alert = {
                        type: 'success',
                        message: 'Copied! You can paste this caption into a new post on social media now'
                    }
                });
            },

            async openShareToFacebookDialog() {
                const token = await localStorage.getItem('auth_token_default');

                try {
                    const response = await window.axios.get('api/fb/pages', {
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    })

                    this.showShareDialog = true;
                    this.fbPages = response.data.data;

                } catch (error) {
                    if (error.response) {
                        this.canShare = error.response.status !== 403
                        this.showShareDialog = false;
                        this.alert = {};
                        this.alert.type = 'error';
                        this.alert.message = error.response.data.message
                    }
                }
            },

            async shareToFacebook() {
                let page = this.fbPages.find(pages => pages.id === this.share.fb.page);

                const token = await localStorage.getItem('auth_token_default');
                const image = await this.getBase64FromUrl(this.groupByTypes[this.selectedType][this.carousel].url)

                try {
                    const response = await window.axios.post('api/fb/pages/' + page.id + '/share', {
                        token: page.access_token,
                        scheduled_publish_time: this.share.fb.datetime,
                        published: false,
                        url: image,
                        caption: this.socialGraphic.description,
                    }, {
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    });

                    if (response.id) {
                        this.showShareDialog = false;
                    }
                } catch (error) {
                    if (error.response) {
                        this.showShareDialog = false;
                        this.alert = {};
                        this.alert.type = 'error';
                        this.alert.message = error.response.data.message
                    }
                }
            },

            getBase64FromUrl(url = '') {
                return new Promise((resolve) => {
                    if (!url.includes('http')) {
                        resolve(url);
                    }

                    const img = new Image();
                    img.src = url.replace(/^.*?:\/\/.*?(?=\/|$)/,'');
                    img.onload = async function () {
                        let canvas = document.createElement('canvas'),
                            ctx = canvas.getContext('2d');

                        canvas.width = img.width;
                        canvas.height = img.height;

                        setTimeout(() => {
                            ctx.drawImage(img, 0, 0);
                            resolve(canvas.toDataURL( 'image/jpg'));
                        },0)
                    }
                });

            },

            handleSendEmail() {

            },
            handleTransformEnd(e) {
                this[this.selectedShapeName+'Config'] = KonvaHelper.handleTransformEnd(e, this[this.selectedShapeName+'Config'])
            },
            handleStageMouseDown(e) {
                // clicked on stage - clear selection
                if (e.target === e.target.getStage()) {
                    this.selectedShapeName = '';
                    KonvaHelper.updateTransformer(this.$refs.transformer, this.selectedShapeName);
                    return;
                }

                // clicked on transformer - do nothing
                if (e.target.getParent().className === 'Transformer') {
                    return;
                }

                // find clicked rect by its name
                this.selectedShapeName = e.target.name()
                    ? e.target.name()
                    : '';

                KonvaHelper.updateTransformer(this.$refs.transformer, this.selectedShapeName);
            },
            handleDownloadKonva() {
                KonvaHelper.downloadKonvaImage(this.$refs.konvaStage, 'infographic.jpg', 'image/jpeg')
                window.axios.post('/api/social-graphics/' + this.socialGraphic.id + '/increment-downloads')
            },

            async initEditor() {
                if (this.editMode) {
                    let reader = new FileReader();
                    reader.readAsDataURL(KonvaHelper.getFile(this.$refs.konvaStage));
                    reader.onloadend = () => {
                        this.groupByTypes[this.selectedType][this.carousel].url = reader.result
                    };
                    this.editMode = false;
                    return;
                }

                this.editMode = true;

                let width = this.$refs.editorContainer.clientWidth;

                let activeImage = this.groupByTypes[this.selectedType][this.carousel]

                let image = await KonvaHelper.loadImage(activeImage.url.replace(/^.*?:\/\/.*?(?=\/|$)/,''), width)
                let logo = await KonvaHelper.loadImage(this.personalization.logo_url)

                this.imageConfig.image = image;

                this.logoConfig.image = logo;
                this.logoConfig.y = 20;
                this.companyConfig.y = 20;
                this.companyConfig.text = this.personalization.company;

                this.configKonva.width = width
                this.configKonva.height = image.height;
            }
        },
        async mounted() {
            const socialGraphicId = this.$route.params.id
            this.socialGraphic = await SocialGraphic.find(socialGraphicId)
            this.selectedType = Object.keys(this.groupByTypes)[0]
            const user = await User.include(['personalization']).find(this.$auth.user().id)
            this.personalization = user.personalization
        },
        watch: {
            selectedType(val, old) {
                if (val !== old) {
                    this.editMode = false
                }
            },
            color(color) {
                if (this.$refs.konvaImage === undefined) {
                    return;
                }

                const rgba = color.rgba;
                const imgNode = this.$refs.konvaImage.getNode();

                imgNode.opacity(rgba.a);
                imgNode.hue(color.hue);

                imgNode.getLayer().batchDraw();

                if (imgNode.width() && imgNode.height()) {
                    imgNode.cache();
                }
            }
        }
    }
</script>

<style scoped>

</style>
